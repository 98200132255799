const characters = [
  {
    name: "Aatrox",
    img: "RiotX_ChampionList_aatrox.jpg",
    hair: "none",
    homeRegion: "none",
    mainRole: "fighter",
    accessories: [],
  },
  {
    name: "Ahri",
    img: "RiotX_ChampionList_ahri.jpg",
    hair: "black",
    homeRegion: "ionia",
    mainRole: "mage",
    accessories: [],
  },
  {
    name: "Akali",
    img: "RiotX_ChampionList_Akali.jpg",
    hair: "black",
    homeRegion: "ionia",
    mainRole: "assassin",
    accessories: ["headpiece", "weapon"],
  },
  {
    name: "Akshan",
    img: "RiotX_ChampionList_akshan.jpg",
    hair: "black",
    homeRegion: "shurima",
    mainRole: "marksman",
    accessories: ["weapon", "facialhair"],
  },
  {
    name: "Alistar",
    img: "RiotX_ChampionList_alistar.jpg",
    hair: "blue",
    homeRegion: "none",
    mainRole: "tank",
    accessories: [],
  },
  {
    name: "Amumu",
    img: "RiotX_ChampionList_amumu.jpg",
    hair: "none",
    homeRegion: "shurima",
    mainRole: "tank",
    accessories: [],
  },
  {
    name: "Anivia",
    img: "RiotX_ChampionList_anivia.jpg",
    hair: "none",
    homeRegion: "freljord",
    mainRole: "mage",
    accessories: [],
  },
  {
    name: "Annie",
    img: "RiotX_ChampionList_annie.jpg",
    hair: "red",
    homeRegion: "none",
    mainRole: "mage",
    accessories: ["headpiece"],
  },
  {
    name: "Aphelios",
    img: "RiotX_ChampionList_aphelios.jpg",
    hair: "black",
    homeRegion: "targon",
    mainRole: "marksman",
    accessories: [],
  },
  {
    name: "Ashe",
    img: "RiotX_ChampionList_ashe.jpg",
    hair: "white",
    homeRegion: "freljord",
    mainRole: "marksman",
    accessories: ["headpiece", "weapon"],
  },
  {
    name: "Aurelion Sol",
    img: "RiotX_ChampionList_aurelionsol.jpg",
    hair: "none",
    homeRegion: "targon",
    mainRole: "mage",
    accessories: [],
  },
  {
    name: "Azir",
    img: "RiotX_ChampionList_azir.jpg",
    hair: "none",
    homeRegion: "shurima",
    mainRole: "mage",
    accessories: ["weapon", "headpiece"],
  },
  {
    name: "Bard",
    img: "RiotX_ChampionList_bard.jpg",
    hair: "white",
    homeRegion: "none",
    mainRole: "support",
    accessories: ["headpiece", "facialhair"],
  },
  {
    name: "Blitzcrank",
    img: "RiotX_ChampionList_blitzcrank.jpg",
    hair: "none",
    homeRegion: "zaun",
    mainRole: "tank",
    accessories: [],
  },
  {
    name: "Brand",
    img: "RiotX_ChampionList_brand.jpg",
    hair: "none",
    homeRegion: "none",
    mainRole: "mage",
    accessories: [],
  },
  {
    name: "Braum",
    img: "RiotX_ChampionList_braum.jpg",
    hair: "brown",
    homeRegion: "freljord",
    mainRole: "support",
    accessories: ["facialhair"],
  },
  {
    name: "Caitlyn",
    img: "RiotX_ChampionList_caitlyn.jpg",
    hair: "purple",
    homeRegion: "piltover",
    mainRole: "marksman",
    accessories: ["weapon", "headpiece"],
  },
  {
    name: "Camille",
    img: "RiotX_ChampionList_camille.jpg",
    hair: "white",
    homeRegion: "piltover",
    mainRole: "fighter",
    accessories: [],
  },
  {
    name: "Cassiopeia",
    img: "RiotX_ChampionList_cassiopeia.jpg",
    hair: "none",
    homeRegion: "noxus",
    mainRole: "mage",
    accessories: ["headpiece"],
  },
  {
    name: "Cho'gath",
    img: "RiotX_ChampionList_chogath.jpg",
    hair: "none",
    homeRegion: "void",
    mainRole: "tank",
    accessories: [],
  },
  {
    name: "Corki",
    img: "RiotX_ChampionList_corki.jpg",
    hair: "white",
    homeRegion: "bandlecity",
    mainRole: "marksman",
    accessories: ["headpiece", "facialhair"],
  },
  {
    name: "Darius",
    img: "RiotX_ChampionList_darius.jpg",
    hair: "black",
    homeRegion: "noxus",
    mainRole: "fighter",
    accessories: ["weapon"],
  },
  {
    name: "Diana",
    img: "RiotX_ChampionList_diana.jpg",
    hair: "white",
    homeRegion: "targon",
    mainRole: "fighter",
    accessories: ["weapon"],
  },
  {
    name: "Dr. Mundo",
    img: "RiotX_ChampionList_drmundo.jpg",
    hair: "black",
    homeRegion: "zaun",
    mainRole: "fighter",
    accessories: ["weapon"],
  },
  {
    name: "Draven",
    img: "RiotX_ChampionList_draven.jpg",
    hair: "brown",
    homeRegion: "noxus",
    mainRole: "marksman",
    accessories: ["weapon", "headpiece", "facialhair"],
  },
  {
    name: "Ekko",
    img: "RiotX_ChampionList_ekko.jpg",
    hair: "white",
    homeRegion: "zaun",
    mainRole: "assassin",
    accessories: ["weapon"],
  },
  {
    name: "Elise",
    img: "RiotX_ChampionList_elise.jpg",
    hair: "red",
    homeRegion: "shadowisles",
    mainRole: "mage",
    accessories: [],
  },
  {
    name: "Evelynn",
    img: "RiotX_ChampionList_evelynn.jpg",
    hair: "white",
    homeRegion: "none",
    mainRole: "assassin",
    accessories: [],
  },
  {
    name: "Ezreal",
    img: "RiotX_ChampionList_ezreal.jpg",
    hair: "blonde",
    homeRegion: "piltover",
    mainRole: "marksman",
    accessories: ["weapon"],
  },
  {
    name: "Fiddlesticks",
    img: "RiotX_ChampionList_fiddlesticks.jpg",
    hair: "none",
    homeRegion: "none",
    mainRole: "mage",
    accessories: ["weapon"],
  },
  {
    name: "Fiora",
    img: "RiotX_ChampionList_fiora.jpg",
    hair: "red",
    homeRegion: "demacia",
    mainRole: "fighter",
    accessories: ["weapon"],
  },
  {
    name: "Fizz",
    img: "RiotX_ChampionList_fizz.jpg",
    hair: "none",
    homeRegion: "bilgewater",
    mainRole: "assassin",
    accessories: ["weapon"],
  },
  {
    name: "Galio",
    img: "RiotX_ChampionList_galio.jpg",
    hair: "none",
    homeRegion: "demacia",
    mainRole: "tank",
    accessories: ["headpiece"],
  },
  {
    name: "Gangplank",
    img: "RiotX_ChampionList_gangplank.jpg",
    hair: "brown",
    homeRegion: "bilgewater",
    mainRole: "fighter",
    accessories: ["weapon", "headpiece", "facialhair"],
  },
  {
    name: "Garen",
    img: "RiotX_ChampionList_garen.jpg",
    hair: "brown",
    homeRegion: "demacia",
    mainRole: "fighter",
    accessories: [],
  },
  {
    name: "Gnar",
    img: "RiotX_ChampionList_gnar.jpg",
    hair: "none",
    homeRegion: "freljord",
    mainRole: "fighter",
    accessories: ["headpiece"],
  },
  {
    name: "Gragas",
    img: "RiotX_ChampionList_gragas.jpg",
    hair: "red",
    homeRegion: "freljord",
    mainRole: "fighter",
    accessories: ["facialhair"],
  },
  {
    name: "Graves",
    img: "RiotX_ChampionList_graves.jpg",
    hair: "brown",
    homeRegion: "bilgewater",
    mainRole: "marksman",
    accessories: ["weapon", "facialhair"],
  },
  {
    name: "Gwen",
    img: "RiotX_ChampionList_gwen.jpg",
    hair: "blue",
    homeRegion: "shadowisles",
    mainRole: "fighter",
    accessories: ["weapon"],
  },
  {
    name: "Hecarim",
    img: "RiotX_ChampionList_hecarim.jpg",
    hair: "none",
    homeRegion: "shadowisles",
    mainRole: "fighter",
    accessories: ["headpiece"],
  },
  {
    name: "Heimerdinger",
    img: "RiotX_ChampionList_heimerdinger.jpg",
    hair: "blonde",
    homeRegion: "piltover",
    mainRole: "mage",
    accessories: ["weapon", "facialhair"],
  },
  {
    name: "Illaoi",
    img: "RiotX_ChampionList_illaoi.jpg",
    hair: "brown",
    homeRegion: "bilgewater",
    mainRole: "fighter",
    accessories: [],
  },
  {
    name: "Irelia",
    img: "RiotX_ChampionList_irelia.jpg",
    hair: "purple",
    homeRegion: "ionia",
    mainRole: "fighter",
    accessories: ["headpiece"],
  },
  {
    name: "Ivern",
    img: "RiotX_ChampionList_ivern.jpg",
    hair: "none",
    homeRegion: "ionia",
    mainRole: "support",
    accessories: ["facialhair"],
  },
  {
    name: "Janna",
    img: "RiotX_ChampionList_janna.jpg",
    hair: "white",
    homeRegion: "zaun",
    mainRole: "support",
    accessories: ["weapon"],
  },
  {
    name: "Jarvan IV",
    img: "RiotX_ChampionList_jarvaniv.jpg",
    hair: "none",
    homeRegion: "demacia",
    mainRole: "tank",
    accessories: ["headpiece", "weapon"],
  },
  {
    name: "Jax",
    img: "RiotX_ChampionList_jax.jpg",
    hair: "none",
    homeRegion: "none",
    mainRole: "fighter",
    accessories: ["weapon", "headpiece"],
  },
  {
    name: "Jayce",
    img: "RiotX_ChampionList_jayce.jpg",
    hair: "brown",
    homeRegion: "piltover",
    mainRole: "fighter",
    accessories: ["weapon"],
  },
  {
    name: "Jhin",
    img: "RiotX_ChampionList_jhin.jpg",
    hair: "none",
    homeRegion: "ionia",
    mainRole: "marksman",
    accessories: ["weapon", "headpiece"],
  },
  {
    name: "Jinx",
    img: "RiotX_ChampionList_jinx.jpg",
    hair: "blue",
    homeRegion: "zaun",
    mainRole: "marksman",
    accessories: ["weapon"],
  },
  {
    name: "Kai'sa",
    img: "RiotX_ChampionList_kaisa.jpg",
    hair: "black",
    homeRegion: "void",
    mainRole: "marksman",
    accessories: ["weapon"],
  },
  {
    name: "Kalista",
    img: "RiotX_ChampionList_kalista.jpg",
    hair: "black",
    homeRegion: "shadowisles",
    mainRole: "marksman",
    accessories: ["weapon", "headpiece"],
  },
  {
    name: "Karma",
    img: "RiotX_ChampionList_karma.jpg",
    hair: "brown",
    homeRegion: "ionia",
    mainRole: "mage",
    accessories: ["headpiece"],
  },
  {
    name: "Karthus",
    img: "RiotX_ChampionList_karthus.jpg",
    hair: "none",
    homeRegion: "shadowisles",
    mainRole: "mage",
    accessories: ["headpiece"],
  },
  {
    name: "Kassadin",
    img: "RiotX_ChampionList_kassadin.jpg",
    hair: "none",
    homeRegion: "void",
    mainRole: "assassin",
    accessories: ["headpiece"],
  },
  {
    name: "Katarina",
    img: "RiotX_ChampionList_katarina.jpg",
    hair: "red",
    homeRegion: "noxus",
    mainRole: "assassin",
    accessories: ["weapon"],
  },
  {
    name: "Kayle",
    img: "RiotX_ChampionList_kayle.jpg",
    hair: "none",
    homeRegion: "demacia",
    mainRole: "fighter",
    accessories: ["headpiece"],
  },
  {
    name: "Kayn",
    img: "RiotX_ChampionList_kayn.jpg",
    hair: "black",
    homeRegion: "ionia",
    mainRole: "fighter",
    accessories: ["weapon"],
  },
  {
    name: "Kennen",
    img: "RiotX_ChampionList_kennen.jpg",
    hair: "none",
    homeRegion: "ionia",
    mainRole: "mage",
    accessories: ["headpiece"],
  },
  {
    name: "Kha'zix",
    img: "RiotX_ChampionList_khazix.jpg",
    hair: "none",
    homeRegion: "void",
    mainRole: "assassin",
    accessories: [],
  },
  {
    name: "Kindred",
    img: "RiotX_ChampionList_kindred.jpg",
    hair: "white",
    homeRegion: "none",
    mainRole: "marksman",
    accessories: ["weapon", "headpiece"],
  },
  {
    name: "Kled",
    img: "RiotX_ChampionList_kled.jpg",
    hair: "white",
    homeRegion: "noxus",
    mainRole: "fighter",
    accessories: ["facialhair", "headpiece"],
  },
  {
    name: "Kog'maw",
    img: "RiotX_ChampionList_kogmaw.jpg",
    hair: "none",
    homeRegion: "void",
    mainRole: "marksman",
    accessories: [],
  },
  {
    name: "Leblanc",
    img: "RiotX_ChampionList_leblanc.jpg",
    hair: "purple",
    homeRegion: "noxus",
    mainRole: "assassin",
    accessories: ["weapon", "headpiece"],
  },
  {
    name: "Lee Sin",
    img: "RiotX_ChampionList_leesin.jpg",
    hair: "brown",
    homeRegion: "ionia",
    mainRole: "fighter",
    accessories: ["facialhair"],
  },
  {
    name: "Leona",
    img: "RiotX_ChampionList_leona.jpg",
    hair: "brown",
    homeRegion: "targon",
    mainRole: "tank",
    accessories: ["weapon", "headpiece"],
  },
  {
    name: "Lillia",
    img: "RiotX_ChampionList_lillia.jpg",
    hair: "purple",
    homeRegion: "ionia",
    mainRole: "fighter",
    accessories: ["weapon"],
  },
  {
    name: "Lissandra",
    img: "RiotX_ChampionList_lissandra.jpg",
    hair: "none",
    homeRegion: "freljord",
    mainRole: "mage",
    accessories: ["headpiece"],
  },
  {
    name: "Lucian",
    img: "RiotX_ChampionList_lucian.jpg",
    hair: "brown",
    homeRegion: "demacia",
    mainRole: "marksman",
    accessories: ["weapon"],
  },
  {
    name: "Lulu",
    img: "RiotX_ChampionList_lulu.jpg",
    hair: "purple",
    homeRegion: "bandlecity",
    mainRole: "support",
    accessories: ["headpiece", "weapon"],
  },
  {
    name: "Lux",
    img: "RiotX_ChampionList_lux.jpg",
    hair: "blonde",
    homeRegion: "demacia",
    mainRole: "mage",
    accessories: ["weapon"],
  },
  {
    name: "Malphite",
    img: "RiotX_ChampionList_malphite.jpg",
    hair: "none",
    homeRegion: "ixtal",
    mainRole: "tank",
    accessories: [],
  },
  {
    name: "Malzahar",
    img: "RiotX_ChampionList_malzahar.jpg",
    hair: "none",
    homeRegion: "void",
    mainRole: "mage",
    accessories: ["headpiece", "weapon"],
  },
  {
    name: "Maokai",
    img: "RiotX_ChampionList_maokai.jpg",
    hair: "none",
    homeRegion: "shadowisles",
    mainRole: "tank",
    accessories: [],
  },
  {
    name: "Master Yi",
    img: "RiotX_ChampionList_masteryi.jpg",
    hair: "brown",
    homeRegion: "ionia",
    mainRole: "assassin",
    accessories: ["weapon", "facialhair", "headpiece"],
  },
  {
    name: "Miss Fortune",
    img: "RiotX_ChampionList_missfortune.jpg",
    hair: "red",
    homeRegion: "bilgewater",
    mainRole: "marksman",
    accessories: ["weapon", "headpiece"],
  },
  {
    name: "Mordekaiser",
    img: "RiotX_ChampionList_mordekaiser.jpg",
    hair: "none",
    homeRegion: "noxus",
    mainRole: "fighter",
    accessories: ["headpiece"],
  },
  {
    name: "Morgana",
    img: "RiotX_ChampionList_morgana.jpg",
    hair: "purple",
    homeRegion: "demacia",
    mainRole: "mage",
    accessories: [],
  },
  {
    name: "Nami",
    img: "RiotX_ChampionList_nami.jpg",
    hair: "none",
    homeRegion: "none",
    mainRole: "support",
    accessories: ["headpiece", "weapon"],
  },
  {
    name: "Nasus",
    img: "RiotX_ChampionList_nasus.jpg",
    hair: "none",
    homeRegion: "shurima",
    mainRole: "fighter",
    accessories: ["headpiece"],
  },
  {
    name: "Nautilus",
    img: "RiotX_ChampionList_nautilus.jpg",
    hair: "none",
    homeRegion: "bilgewater",
    mainRole: "tank",
    accessories: ["headpiece", "weapon"],
  },
  {
    name: "Neeko",
    img: "RiotX_ChampionList_neeko.jpg",
    hair: "purple",
    homeRegion: "ixtal",
    mainRole: "mage",
    accessories: [],
  },
  {
    name: "Nidalee",
    img: "RiotX_ChampionList_nidalee.jpg",
    hair: "brown",
    homeRegion: "ixtal",
    mainRole: "assassin",
    accessories: ["weapon"],
  },
  {
    name: "Nocturne",
    img: "RiotX_ChampionList_nocturne.jpg",
    hair: "none",
    homeRegion: "none",
    mainRole: "assassin",
    accessories: [],
  },
  {
    name: "Nunu & Willump",
    img: "RiotX_ChampionList_nunu.jpg",
    hair: "brown",
    homeRegion: "freljord",
    mainRole: "tank",
    accessories: ["headpiece"],
  },
  {
    name: "Olaf",
    img: "RiotX_ChampionList_olaf.jpg",
    hair: "orange",
    homeRegion: "freljord",
    mainRole: "fighter",
    accessories: ["headpiece", "weapon", "facialhair"],
  },
  {
    name: "Orianna",
    img: "RiotX_ChampionList_orianna.jpg",
    hair: "brown",
    homeRegion: "piltover",
    mainRole: "mage",
    accessories: [],
  },
  {
    name: "Ornn",
    img: "RiotX_ChampionList_ornn.jpg",
    hair: "black",
    homeRegion: "freljord",
    mainRole: "tank",
    accessories: ["facialhair"],
  },
  {
    name: "Pantheon",
    img: "RiotX_ChampionList_pantheon.jpg",
    hair: "none",
    homeRegion: "targon",
    mainRole: "fighter",
    accessories: ["headpiece"],
  },
  {
    name: "Poppy",
    img: "RiotX_ChampionList_poppy.jpg",
    hair: "white",
    homeRegion: "demacia",
    mainRole: "tank",
    accessories: ["weapon"],
  },
  {
    name: "Pyke",
    img: "RiotX_ChampionList_pyke.jpg",
    hair: "none",
    homeRegion: "bilgewater",
    mainRole: "assassin",
    accessories: ["weapon", "headpiece"],
  },
  {
    name: "Qiyana",
    img: "RiotX_ChampionList_qiyana.jpg",
    hair: "white",
    homeRegion: "ixtal",
    mainRole: "assassin",
    accessories: ["weapon", "headpiece"],
  },
  {
    name: "Quinn",
    img: "RiotX_ChampionList_quinn.jpg",
    hair: "brown",
    homeRegion: "demacia",
    mainRole: "marksman",
    accessories: ["headpiece", "weapon"],
  },
  {
    name: "Rakan",
    img: "RiotX_ChampionList_rakan.jpg",
    hair: "white",
    homeRegion: "ionia",
    mainRole: "support",
    accessories: ["facialhair"],
  },
  {
    name: "Rammus",
    img: "RiotX_ChampionList_rammus.jpg",
    hair: "none",
    homeRegion: "shurima",
    mainRole: "tank",
    accessories: ["headpiece"],
  },
  {
    name: "Rek'sai",
    img: "RiotX_ChampionList_reksai.jpg",
    hair: "none",
    homeRegion: "void",
    mainRole: "fighter",
    accessories: [],
  },
  {
    name: "Rell",
    img: "RiotX_ChampionList_rell.jpg",
    hair: "blonde",
    homeRegion: "none",
    mainRole: "tank",
    accessories: ["weapon"],
  },
  {
    name: "Renekton",
    img: "RiotX_ChampionList_renekton.jpg",
    hair: "none",
    homeRegion: "shurima",
    mainRole: "fighter",
    accessories: ["headpiece"],
  },
  {
    name: "Rengar",
    img: "RiotX_ChampionList_rengar.jpg",
    hair: "white",
    homeRegion: "ixtal",
    mainRole: "assassin",
    accessories: ["facialhair", "weapon"],
  },
  {
    name: "Riven",
    img: "RiotX_ChampionList_riven.jpg",
    hair: "white",
    homeRegion: "noxus",
    mainRole: "fighter",
    accessories: ["weapon"],
  },
  {
    name: "Rumble",
    img: "RiotX_ChampionList_rumble.jpg",
    hair: "blue",
    homeRegion: "bandlecity",
    mainRole: "fighter",
    accessories: [],
  },
  {
    name: "Ryze",
    img: "RiotX_ChampionList_ryze.jpg",
    hair: "black",
    homeRegion: "none",
    mainRole: "mage",
    accessories: ["facialhair"],
  },
  {
    name: "Samira",
    img: "RiotX_ChampionList_samira.jpg",
    hair: "brown",
    homeRegion: "noxus",
    mainRole: "marksman",
    accessories: ["weapon", "headpiece"],
  },
  {
    name: "Sejuani",
    img: "RiotX_ChampionList_sejuani.jpg",
    hair: "white",
    homeRegion: "freljord",
    mainRole: "tank",
    accessories: ["weapon", "headpiece"],
  },
  {
    name: "Senna",
    img: "RiotX_ChampionList_senna.jpg",
    hair: "black",
    homeRegion: "none",
    mainRole: "marksman",
    accessories: ["weapon", "headpiece"],
  },
  {
    name: "Seraphine",
    img: "RiotX_ChampionList_seraphine.jpg",
    hair: "pink",
    homeRegion: "piltover",
    mainRole: "mage",
    accessories: ["weapon"],
  },
  {
    name: "Sett",
    img: "RiotX_ChampionList_sett.jpg",
    hair: "pink",
    homeRegion: "ionia",
    mainRole: "fighter",
    accessories: [],
  },
  {
    name: "Shaco",
    img: "RiotX_ChampionList_shaco.jpg",
    hair: "none",
    homeRegion: "none",
    mainRole: "assassin",
    accessories: ["headpiece", "weapon"],
  },
  {
    name: "Shen",
    img: "RiotX_ChampionList_shen.jpg",
    hair: "none",
    homeRegion: "ionia",
    mainRole: "tank",
    accessories: ["headpiece", "weapon"],
  },
  {
    name: "Shyvana",
    img: "RiotX_ChampionList_shyvana.jpg",
    hair: "purple",
    homeRegion: "demacia",
    mainRole: "fighter",
    accessories: ["weapon", "headpiece"],
  },
  {
    name: "Singed",
    img: "RiotX_ChampionList_singed.jpg",
    hair: "none",
    homeRegion: "zaun",
    mainRole: "tank",
    accessories: ["headpiece", "weapon"],
  },
  {
    name: "Sion",
    img: "RiotX_ChampionList_sion.jpg",
    hair: "none",
    homeRegion: "noxus",
    mainRole: "tank",
    accessories: ["headpiece"],
  },
  {
    name: "Sivir",
    img: "RiotX_ChampionList_sivir.jpg",
    hair: "brown",
    homeRegion: "shurima",
    mainRole: "marksman",
    accessories: ["weapon", "headpiece"],
  },
  {
    name: "Skarner",
    img: "RiotX_ChampionList_skarner.jpg",
    hair: "none",
    homeRegion: "shurima",
    mainRole: "fighter",
    accessories: [],
  },
  {
    name: "Sona",
    img: "RiotX_ChampionList_sona.jpg",
    hair: "blue",
    homeRegion: "demacia",
    mainRole: "support",
    accessories: ["weapon"],
  },
  {
    name: "Soraka",
    img: "RiotX_ChampionList_soraka.jpg",
    hair: "white",
    homeRegion: "targon",
    mainRole: "support",
    accessories: ["weapon"],
  },
  {
    name: "Swain",
    img: "RiotX_ChampionList_swain.jpg",
    hair: "white",
    homeRegion: "noxus",
    mainRole: "mage",
    accessories: [],
  },
  {
    name: "Sylas",
    img: "RiotX_ChampionList_sylas.jpg",
    hair: "black",
    homeRegion: "demacia",
    mainRole: "mage",
    accessories: ["facialhair"],
  },
  {
    name: "Syndra",
    img: "RiotX_ChampionList_syndra.jpg",
    hair: "white",
    homeRegion: "ionia",
    mainRole: "mage",
    accessories: ["headpiece"],
  },
  {
    name: "Tahm Kench",
    img: "RiotX_ChampionList_tahmkench.jpg",
    hair: "none",
    homeRegion: "bilgewater",
    mainRole: "support",
    accessories: ["headpiece"],
  },
  {
    name: "Taliyah",
    img: "RiotX_ChampionList_taliyah.jpg",
    hair: "brown",
    homeRegion: "shurima",
    mainRole: "mage",
    accessories: ["headpiece"],
  },
  {
    name: "Talon",
    img: "RiotX_ChampionList_talon.jpg",
    hair: "none",
    homeRegion: "noxus",
    mainRole: "assassin",
    accessories: ["weapon", "headpiece"],
  },
  {
    name: "Taric",
    img: "RiotX_ChampionList_taric.jpg",
    hair: "brown",
    homeRegion: "targon",
    mainRole: "support",
    accessories: ["weapon"],
  },
  {
    name: "Teemo",
    img: "RiotX_ChampionList_teemo.jpg",
    hair: "brown",
    homeRegion: "bandlecity",
    mainRole: "marksman",
    accessories: ["weapon", "headpiece"],
  },
  {
    name: "Thresh",
    img: "RiotX_ChampionList_thresh.jpg",
    hair: "none",
    homeRegion: "shadowisles",
    mainRole: "support",
    accessories: ["weapon"],
  },
  {
    name: "Tristana",
    img: "RiotX_ChampionList_tristana.jpg",
    hair: "white",
    homeRegion: "bandlecity",
    mainRole: "marksman",
    accessories: ["weapon"],
  },
  {
    name: "Trundle",
    img: "RiotX_ChampionList_trundle.jpg",
    hair: "pink",
    homeRegion: "freljord",
    mainRole: "fighter",
    accessories: ["weapon", "facialhair"],
  },
  {
    name: "Tryndamere",
    img: "RiotX_ChampionList_tryndamere.jpg",
    hair: "black",
    homeRegion: "freljord",
    mainRole: "fighter",
    accessories: ["weapon", "headpiece"],
  },
  {
    name: "Twisted Fate",
    img: "RiotX_ChampionList_twistedfate.jpg",
    hair: "black",
    homeRegion: "bilgewater",
    mainRole: "mage",
    accessories: ["headpiece", "facialhair"],
  },
  {
    name: "Twitch",
    img: "RiotX_ChampionList_twitch.jpg",
    hair: "brown",
    homeRegion: "zaun",
    mainRole: "marksman",
    accessories: ["headpiece", "weapon"],
  },
  {
    name: "Udyr",
    img: "RiotX_ChampionList_udyr.jpg",
    hair: "black",
    homeRegion: "freljord",
    mainRole: "fighter",
    accessories: ["headpiece", "facialhair"],
  },
  {
    name: "Urgot",
    img: "RiotX_ChampionList_urgot.jpg",
    hair: "none",
    homeRegion: "zaun",
    mainRole: "fighter",
    accessories: ["headpiece"],
  },
  {
    name: "Varus",
    img: "RiotX_ChampionList_varus.jpg",
    hair: "white",
    homeRegion: "ionia",
    mainRole: "marksman",
    accessories: ["weapon", "headpiece"],
  },
  {
    name: "Vayne",
    img: "RiotX_ChampionList_vayne.jpg",
    hair: "purple",
    homeRegion: "demacia",
    mainRole: "marksman",
    accessories: ["weapon", "headpiece"],
  },
  {
    name: "Veigar",
    img: "RiotX_ChampionList_veigar.jpg",
    hair: "none",
    homeRegion: "bandlecity",
    mainRole: "mage",
    accessories: ["headpiece"],
  },
  {
    name: "Vel'koz",
    img: "RiotX_ChampionList_velkoz.jpg",
    hair: "none",
    homeRegion: "void",
    mainRole: "mage",
    accessories: [],
  },
  {
    name: "Vi",
    img: "RiotX_ChampionList_vi.jpg",
    hair: "pink",
    homeRegion: "piltover",
    mainRole: "fighter",
    accessories: ["weapon", "headpiece"],
  },
  {
    name: "Viego",
    img: "RiotX_ChampionList_viego.jpg",
    hair: "white",
    homeRegion: "shadowisles",
    mainRole: "assassin",
    accessories: ["headpiece", "weapon"],
  },
  {
    name: "Viktor",
    img: "RiotX_ChampionList_viktor.jpg",
    hair: "black",
    homeRegion: "zaun",
    mainRole: "mage",
    accessories: ["headpiece"],
  },
  {
    name: "Vladimir",
    img: "RiotX_ChampionList_vladimir.jpg",
    hair: "blonde",
    homeRegion: "noxus",
    mainRole: "mage",
    accessories: [],
  },
  {
    name: "Volibear",
    img: "RiotX_ChampionList_volibear.jpg",
    hair: "white",
    homeRegion: "freljord",
    mainRole: "fighter",
    accessories: [],
  },
  {
    name: "Warwick",
    img: "RiotX_ChampionList_warwick.jpg",
    hair: "black",
    homeRegion: "zaun",
    mainRole: "fighter",
    accessories: [],
  },
  {
    name: "Wukong",
    img: "RiotX_ChampionList_wukong.jpg",
    hair: "brown",
    homeRegion: "ionia",
    mainRole: "fighter",
    accessories: ["facialhair"],
  },
  {
    name: "Xayah",
    img: "RiotX_ChampionList_xayah.jpg",
    hair: "purple",
    homeRegion: "ionia",
    mainRole: "marksman",
    accessories: ["headpiece", "weapon"],
  },
  {
    name: "Xerath",
    img: "RiotX_ChampionList_xerath.jpg",
    hair: "none",
    homeRegion: "shurima",
    mainRole: "mage",
    accessories: [],
  },
  {
    name: "Xin Zhao",
    img: "RiotX_ChampionList_xinzhao.jpg",
    hair: "black",
    homeRegion: "demacia",
    mainRole: "fighter",
    accessories: ["weapon"],
  },
  {
    name: "Yasuo",
    img: "RiotX_ChampionList_yasuo.jpg",
    hair: "brown",
    homeRegion: "ionia",
    mainRole: "fighter",
    accessories: ["weapon"],
  },
  {
    name: "Yone",
    img: "RiotX_ChampionList_yone.jpg",
    hair: "black",
    homeRegion: "ionia",
    mainRole: "assassin",
    accessories: ["weapon", "headpiece"],
  },
  {
    name: "Yorick",
    img: "RiotX_ChampionList_yorick.jpg",
    hair: "black",
    homeRegion: "shadowisles",
    mainRole: "fighter",
    accessories: ["weapon", "headpiece", "facialhair"],
  },
  {
    name: "Yuumi",
    img: "RiotX_ChampionList_yuumi.jpg",
    hair: "white",
    homeRegion: "bandlecity",
    mainRole: "support",
    accessories: ["headpiece"],
  },
  {
    name: "Zac",
    img: "RiotX_ChampionList_zac.jpg",
    hair: "none",
    homeRegion: "zaun",
    mainRole: "tank",
    accessories: [],
  },
  {
    name: "Zed",
    img: "RiotX_ChampionList_zed.jpg",
    hair: "none",
    homeRegion: "ionia",
    mainRole: "assassin",
    accessories: ["weapon", "headpiece"],
  },
  {
    name: "Ziggs",
    img: "RiotX_ChampionList_ziggs.jpg",
    hair: "brown",
    homeRegion: "zaun",
    mainRole: "mage",
    accessories: ["weapon", "headpiece"],
  },
  {
    name: "Zilean",
    img: "RiotX_ChampionList_zilean.jpg",
    hair: "blue",
    homeRegion: "none",
    mainRole: "support",
    accessories: [],
  },
  {
    name: "Zoe",
    img: "RiotX_ChampionList_zoe.jpg",
    hair: "orange",
    homeRegion: "targon",
    mainRole: "mage",
    accessories: [],
  },
  {
    name: "Zyra",
    img: "RiotX_ChampionList_zyra.jpg",
    hair: "red",
    homeRegion: "ixtal",
    mainRole: "mage",
    accessories: [],
  },
];

export { characters };
